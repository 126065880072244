<template>
    <div class="home">
      <v-layout row wrap class="d-flex justify-center">
        <v-flex xs12 md12 lg12 class="mb-5">
          <h1 class="f-alfa primary--text">
            <router-link to="/">Dashboard</router-link> / Agents
          </h1>
        </v-flex>
        <v-flex xs12 md4 class="pa-2">
          <div style="border: 1px solid #ba9d73;border-radius: 5px;padding: 15px;background-color: #212121;">
            <v-icon style="font-size: 64px;" class="primary--text">mdi-account</v-icon>
            <h2 class="primary--text">Agents - {{ agents.length }}</h2>
            <p class="accent--text">Blocked Agents - 0</p>
            
          </div>
          
        </v-flex>
        <v-flex xs12 md4 class="pa-2">
          <div style="border: 1px solid #ba9d73;border-radius: 5px;padding: 15px;background-color: #212121;height: 100%;">
            <v-icon style="font-size: 64px;" class="primary--text">mdi-helicopter</v-icon>
            <h2 class="primary--text">Booking - 0</h2>
            <p class="accent--text">This week - 0</p>
            <!-- <v-btn class="primary black--text" x-large block @click="addAgent">Add Agent</v-btn> -->
          </div>
        </v-flex>
        <v-flex xs12 md4 class="pa-2">
          <div style="border: 1px solid #ba9d73;border-radius: 5px;padding: 15px;background-color: #212121;height: 100%;">
            <v-icon style="font-size: 64px;" class="primary--text">mdi-currency-usd</v-icon>
            <h2 class="primary--text">Pmt. Due - 0 AED</h2>
            <p class="accent--text">Pmt. Completed - 0</p>
            <!-- <v-btn class="primary black--text" x-large block @click="addAgent">Add Agent</v-btn> -->
          </div>
        </v-flex>
        <v-flex xs12 class="pa-2">
          <v-divider class="mt-10"></v-divider>
        </v-flex>
        <v-flex xs12 class="mb-5 mt-10 pa-2">
          <v-layout row wrap class="d-flex justify-space-between align-center">
            <v-flex xs8 md6 lg4>
              <v-text-field
                single-line
                outlined
                label="Type to search.."
                prepend-inner-icon="mdi-magnify"
                v-model="searchText"
              ></v-text-field>
            </v-flex>
            <v-flex xs4 md4 lg4 class="d-flex justify-end">
              <v-btn class="primary black--text" x-large  @click="addAgent">Add Agent</v-btn>
            </v-flex>
          </v-layout>
        </v-flex>
        <v-flex xs12 class="pa-2">
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left uppercase primary--text">#</th>
                  <th class="text-left uppercase primary--text">Reference ID</th>
                  <th class="text-left uppercase primary--text">Name</th>
                  <th class="text-left uppercase primary--text">Phone</th>
                  <th class="text-left uppercase primary--text">Email</th>
                  
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item,index) in filteredAgents" :key="item._id">
                  <td>{{ index + 1 }}</td>
                  <td>{{ item.ref_id }}</td>
                  <td>{{ item.name }}</td>
                  <td>{{ item.phone }}</td>
                  <td>{{ item.email }}</td>
                  
                  <td>
                    <v-btn
                      small
                      class="primary black--text"
                      @click="viewAccount(item._id)"
                      ><v-icon>mdi-magnify</v-icon></v-btn
                    >
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-flex>
      </v-layout>
    </div>
  </template>
  
  <script>
  import Axios from "axios";
  import { BASE_URL } from "@/config";
import AddAgent from "./AddAgent.vue";
  export default {
    data() {
      return {
        agents: [],
        searchText: "",
      };
    },
    mounted() {
      this.fetchAgents();
    },
    computed: {
      filteredAgents() {
        const query = this.searchText.toLowerCase();
        if (query.length >= 3) {
          return this.agents.filter((item) =>
            JSON.stringify(item).toLowerCase().includes(query)
          );
        } else {
          return this.agents;
        }
      },
    },
    methods: {
      async fetchAgents() {
        let url = BASE_URL + "/agent";
        let { data } = await Axios.get(url);
        this.agents = data;
      },
      viewAccount(id) {
        this.$router.push({ name: "ViewAgent", params: { id: id } });
      },
      addAgent() {
        this.$router.push("/agents/add-agent");
      },
    },
  };
  </script>
  
  
  